import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

export const query = graphql`
  query($id: String!) {
    allContentfulGallery(filter: { category: { id: { eq: $id } } }) {
      edges {
        node {
          id
          slug
          name
          images {
            fluid(maxWidth: 255, maxHeight: 255) {
              ...GatsbyContentfulFluid_withWebp
            }
            title
          }
          category {
            name
            id
            seo {
              title
              description
              keywords
            }
          }
        }
      }
    }
  }
`

const GalleryCategory = ({ data }) => {
  const subCategories = data.allContentfulGallery.edges
  const category =
    subCategories && subCategories.length > 0
      ? subCategories[0].node.category
      : {}

  return (
    <Layout>
      <SEO
        title={category.seo.title ? category.seo.title + " | Gallery" : ""}
        description={
          category.seo.description
            ? category.seo.description + " | Gallery"
            : ""
        }
        keywords={
          category.seo.keywords ? category.seo.keywords + " | Gallery" : ""
        }
      />
      <div>
        <section className="inner-intro bg-img-single-service light-color overlay-before parallax-background">
          <div className="container">
            <div className="row title">
              <div className="title_row">
                <h1 data-title="Furniture">
                  <span>{category.name}</span>
                </h1>
                <div className="page-breadcrumb">
                  <Link to="/">Home</Link> &gt;{" "}
                  <Link to="/gallery/">Gallery</Link> &gt;{" "}
                  <span>{category.name}</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="service" className="padding ptb-xs-40">
          <div className="container">
            <div className="row pb-30">
              <div className="col-md-12">
                <ul className="container-filter categories-filter">
                  <li>
                    <Link to="/gallery/">Back to Gallery</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row text-center mb-40">
              <div className="col-lg-6 offset-lg-3 sect-title">
                <h2>
                  <span>{category.name}</span> Types
                </h2>
                <span className="title_bdr">
                  <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                </span>
              </div>
            </div>
            <div id="services-widgets" className="row">
              {subCategories.map(subCategory => (
                <div
                  key={subCategory.node.id}
                  className="services-block col-lg-3 col-md-6 col-xs-12 mb-30"
                >
                  <div className="inner-box hvr-float">
                    <Link to={subCategory.node.slug}>
                      <div className="image">
                        {subCategory.node.images &&
                          subCategory.node.images.length > 0 && (
                            <Img
                              fluid={subCategory.node.images[0].fluid}
                              alt={subCategory.node.images[0].title}
                              title={subCategory.node.images[0].title}
                            />
                          )}
                      </div>
                      <div className="lower-box">
                        <h3>{subCategory.node.name}</h3>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default GalleryCategory
